import { gql, useMutation, useQuery } from '@apollo/client'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useState, useEffect } from 'react'

import { appErrors } from '@lib/apollo/apolloCache'
import { TO_REDIRECT_ROUTE } from '@lib/apollo/constants'

import AppErrorSnackbar from '@components_pop/errors/AppErrorSnackbar'

import LoginPage from './view'

const CONFIGURATION = gql`
  query GetConfiguration {
    configuration {
      useAuth0
    }
  }
`

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        email
      }
    }
  }
`

const LoginPageContainer = () => {
  const { loading: loadingConfig, data: configurationData } = useQuery(CONFIGURATION)
  const [loading, setLoading] = useState(false)
  const [login] = useMutation(LOGIN)
  const router = useRouter()

  useEffect(() => {
    // Ensure there's no masquerade session id hanging around
    sessionStorage.clear()
  }, [])

  if (loadingConfig) return null

  const useLegacyAuth = process.env.USE_LEGACY_AUTH
  let forceAuth0 = !useLegacyAuth // Use env default if configuration query fails

  if (configurationData && !useLegacyAuth) {
    forceAuth0 = configurationData.configuration.useAuth0
  }

  if (forceAuth0) {
    router.push('/api/auth/login')
    return null
  }

  const onSubmit = ({ email, password }) => {
    // we only have login errors for now so we can clear all
    // so we don't stack or propagate to after login
    appErrors([])
    setLoading(true)
    login({
      variables: { email, password },
    }).then(
      (res) => {
        if (!res.errors) {
          const toRedirectRoute = localStorage?.getItem(TO_REDIRECT_ROUTE)
          router.push(toRedirectRoute || '/')
          localStorage.removeItem(TO_REDIRECT_ROUTE)
        } else {
          setLoading(false)
        }
      },
      (/* err */) => {
        setLoading(false)
      }
    )
  }

  return (
    <>
      <Head>
        <title>Gembah - Login</title>
      </Head>
      <LoginPage onSubmit={onSubmit} loading={loading} />
      <AppErrorSnackbar />
    </>
  )
}

export default LoginPageContainer

// Remove all previous code and uncomment the following code to force login through the auth0 flow
/*

import { useRouter } from 'next/router'
import { useEffect } from 'react'

const LoginPageContainer = () => {
  const router = useRouter()

  useEffect(() => {
    // Ensure there's no masquerade session id hanging around
    sessionStorage.clear()
    router.push('/api/auth/login')
  }, [])

  return null
}

export default LoginPageContainer
*/
