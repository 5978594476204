import { useQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'

import { appErrors } from '@lib/apollo/apolloCache'

import { GET_APP_ERRORS } from './graphql'
import AppErrorSnackbar from './view'

const AppErrorSnackbarContainer = () => {
  const [appErrorMessage, setAppErrorMessage] = useState(null)
  const {
    // eslint-disable-next-line no-unused-vars
    loading, // TODO: how do we want to handle this UX-wise, if at all?
    // eslint-disable-next-line no-unused-vars
    error, // TODO: how do we want to handle this UX-wise, if at all?
    data,
  } = useQuery(GET_APP_ERRORS)

  useEffect(() => {
    const errors = data?.appErrors || []
    if (errors.length) {
      const message = (
        <>
          {errors.map((errMsg) => (
            <div key={errMsg}>{errMsg}</div>
          ))}
        </>
      )
      setAppErrorMessage(message)
    }
  }, [data])

  const handleCloseAppError = () => {
    appErrors([])
    setAppErrorMessage('')
  }

  return (
    <AppErrorSnackbar
      open={Boolean(appErrorMessage)}
      onClose={handleCloseAppError}
      message={appErrorMessage}
    />
  )
}

export default AppErrorSnackbarContainer
