import PropTypes from 'prop-types'
import React from 'react'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.error.main,
    },
  },
  iconitem: {
    display: 'flex',
    '& svg': {
      marginRight: theme.spacing(),
    },
  },
}))

const AppErrorSnackbar = ({ open, onClose, message }) => {
  const classes = useStyles()

  return (
    <Snackbar
      classes={{ root: classes.root }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={onClose}
      ClickAwayListenerProps={{
        onClickAway: () => {},
      }}
      message={
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item className={classes.iconitem}>
            <SvgIcon>
              <ErrorOutlineIcon />
            </SvgIcon>
          </Grid>
          <Grid item>{message}</Grid>
        </Grid>
      }
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}

AppErrorSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
}

AppErrorSnackbar.defaultProps = {
  message: '',
}

export default AppErrorSnackbar
