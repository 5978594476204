import { yupResolver } from '@hookform/resolvers'
import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { makeAlternativeTwoSidesLayout, MOBILE_BREAKPOINT } from '@lib/theme'

import GembahPop from '@components_pop/svg/GembahPop'

const BOOTSTRAP_HIGHLIGHT = '0 0 0 .2rem rgba(0,123,255,.25)'
const RESET_URL = `https://${process.env.SIGNUP_HOST}/password-reset`

const useStyles = makeStyles((theme) => ({
  ...makeAlternativeTwoSidesLayout(theme),
  resetLink: {
    color: theme.palette.text.primary,
    marginLeft: 16,
    textAlign: 'right',
  },
  input: {
    border: 'none',
    borderBottom: '1px solid #adb7b9',
    boxSizing: 'border-box',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: 16,
    fontWeight: 400,
    height: 35,
    padding: '6px 12px',
    width: '100%',
    '&:focus': {
      boxShadow: BOOTSTRAP_HIGHLIGHT,
      outline: 0,
    },
  },
  inputError: {
    display: 'block',
    color: 'red',
    fontSize: 11,
    margin: '10px 0 20px',
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      margin: '10px 0 50px',
    },
  },
  label: {
    display: 'inline-block',
    fontSize: 16,
    lineHeight: '21px',
    marginBottom: '.5rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'inherit',
  },
  submit: {
    cursor: ({ loading }) => (loading ? 'default' : 'pointer'),
    '-webkit-appearance': 'none', // fixes button appearance on ios
    backgroundColor: ({ loading }) => (loading ? '#c9d0d1' : '#57595c'),
    fontFamily: 'inherit',
    borderRadius: '2px',
    border: 'none',
    color: theme.palette.background.default,
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 500,
    height: 32,
    margin: '0 auto 0 0',
    padding: '7px 40px',
    '&:focus': {
      boxShadow: BOOTSTRAP_HIGHLIGHT,
      outline: 'none',
    },
    width: 138,
  },
  error: {
    color: '#dc3545',
    fontWeight: 500,
    left: 0,
    margin: 0,
    padding: 20,
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: 0,
    width: '100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > input[type="submit"]': {
      width: '100%',
      marginBottom: 25,
    },
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      flexDirection: 'row',
      alignItems: 'self',
      justifyContent: 'self',
      '& > input[type="submit"]': {
        width: 'auto',
        marginBottom: 0,
      },
    },
  },
}))

const LoginPage = ({ onSubmit, loading }) => {
  const classes = useStyles({ loading })

  const { register, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email('Please enter a valid email')
          .trim()
          .required('Email is required'),
        password: Yup.string().trim().required('Password is required'),
      })
    ),
  })

  return (
    <div className={classes.container}>
      <div className={classes.leftPanel}>
        <div className={classes.logo}>
          <GembahPop />
        </div>
      </div>
      <div className={classes.rightPanel}>
        <Typography className={classes.title} variant="h1">
          Log in to your account
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {/* TODO: display errors from the server
          <div className={classes.error}>Wrong email or password.</div> */}
          <label className={classes.label} htmlFor="email">
            Email*
            <input
              name="email"
              ref={register}
              id="email"
              data-test-id="user-email"
              className={classes.input}
            />
            <span className={classes.inputError}>{errors.email?.message}</span>
          </label>
          <label className={classes.label} htmlFor="password">
            Password*
            <input
              name="password"
              ref={register}
              id="password"
              data-test-id="user-password"
              className={classes.input}
              type="password"
            />
            <span className={classes.inputError}>{errors.password?.message}</span>
          </label>
          <div className={classes.actions}>
            <input
              className={classes.submit}
              disabled={loading}
              type="submit"
              value={loading ? 'Loading...' : 'Submit'}
            />
            <a className={classes.resetLink} href={RESET_URL}>
              Forgot your password?
            </a>
          </div>
        </form>
      </div>
    </div>
  )
}

LoginPage.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

export default LoginPage
